





















































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import FinancialInstitution from "@/models/financialInstitution";
import Loader from "@/helpers/loader";
import Notifier from "@/helpers/notifier";
import { v4 as uuid } from "uuid";
import agent from "@/helpers/agent";

@Component
export default class Profile extends Vue {
  financialInstitution: FinancialInstitution = {
    financialInstitutionID: "",
    name: "",
    legalName: "",
    physicalAddress: "",
    type: 0,
    email: "",
    phone: "",
    website: "",
    logoPath: "",
    isActive: true,
    users: [],
  };
  types = [
    {
      value: 0,
      label: "Bank",
    },
    {
      value: 1,
      label: "Non-Bank",
    },
    {
      value: 2,
      label: "Regulator",
    },
  ];
  onSubmit() {
    Loader.showLoader();
    if (this.financialInstitution.financialInstitutionID.length === 0) {
      agent.FinancialInstitutions.create({
        ...this.financialInstitution,
        financialInstitutionID: uuid(),
      })
        .then(() => {
          Loader.hideLoader();
          Notifier.showPositive(
            `You have succesfully added the financialInstitution`
          );
          this.$router.push("/list-financialinstitution");
        })
        .catch(() => [
          Loader.hideLoader(),
          Notifier.showError(
            "There was an error creating the financial institution"
          ),
        ]);
      return;
    }
    agent.FinancialInstitutions.update(this.financialInstitution)
      .then(() => {
        Loader.hideLoader();
        Notifier.showPositive(
          `You have succesfully updated the finacial institution`
        );
        this.$router.push("/list-finacialinstitution");
      })
      .catch(() => [
        Loader.hideLoader(),
        Notifier.showError(
          "There was an error updating the finacial institution"
        ),
      ]);
  }
  async mounted() {
    if (this.$route.query.id) {
      this.financialInstitution = await agent.FinancialInstitutions.details(
        this.$route.query.id as string
      );
    }
  }
}
