var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-pt-xl"},[_c('h3',{staticClass:"text-h6"},[_vm._v("Add Financial Institution")]),_c('q-card',{class:_vm.$q.screen.gt.sm ? 'q-pa-sm' : ''},[_c('q-card-section',{staticClass:"q-pa-lg"},[_c('q-form',{staticClass:"q-gutter-md",on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"row items-start q-pl-md q-col-gutter-x-md"},[_c('q-input',{staticClass:"col-12 col-md-6",attrs:{"outlined":"","bottom-slots":"","label":"Name","lazy-rules":"","rules":[
              function (val) { return (val.trim() && val.length > 0) || 'Please type something'; } ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"cancel"},on:{"click":function($event){_vm.text = ''}}})]},proxy:true},{key:"hint",fn:function(){return [_vm._v(" Type name of the institution ")]},proxy:true}]),model:{value:(_vm.financialInstitution.name),callback:function ($$v) {_vm.$set(_vm.financialInstitution, "name", $$v)},expression:"financialInstitution.name"}}),_c('q-input',{staticClass:"col-12 col-md-6",attrs:{"outlined":"","bottom-slots":"","label":"Legal Name","lazy-rules":"","rules":[
              function (val) { return (val.trim() && val.length > 0) || 'Please type something'; } ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"cancel"},on:{"click":function($event){_vm.text = ''}}})]},proxy:true},{key:"hint",fn:function(){return [_vm._v(" Type your institution legal name ")]},proxy:true}]),model:{value:(_vm.financialInstitution.legalName),callback:function ($$v) {_vm.$set(_vm.financialInstitution, "legalName", $$v)},expression:"financialInstitution.legalName"}})],1),_c('div',{staticClass:"row items-start q-pl-md q-col-gutter-x-md"},[_c('q-input',{staticClass:"col-12 col-md-12",attrs:{"outlined":"","bottom-slots":"","label":"Logo","lazy-rules":"","rules":[
              function (val) { return (val.trim() && val.length > 0) || 'Please type something'; } ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"cancel"},on:{"click":function($event){_vm.text = ''}}})]},proxy:true},{key:"hint",fn:function(){return [_vm._v(" Type the url of your logo")]},proxy:true}]),model:{value:(_vm.financialInstitution.logoPath),callback:function ($$v) {_vm.$set(_vm.financialInstitution, "logoPath", $$v)},expression:"financialInstitution.logoPath"}})],1),_c('div',{staticClass:"row items-start q-pl-md q-col-gutter-x-md"},[_c('q-input',{staticClass:"col-12 col-md-8",attrs:{"outlined":"","bottom-slots":"","label":"Website","lazy-rules":"","rules":[
              function (val) { return (val.trim() && val.length > 0) || 'Please type something'; } ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"cancel"},on:{"click":function($event){_vm.text = ''}}})]},proxy:true},{key:"hint",fn:function(){return [_vm._v(" Type the websiste address of the institution ")]},proxy:true}]),model:{value:(_vm.financialInstitution.website),callback:function ($$v) {_vm.$set(_vm.financialInstitution, "website", $$v)},expression:"financialInstitution.website"}}),_c('q-input',{staticClass:"col-12 col-md-4",attrs:{"outlined":"","bottom-slots":"","label":"Phone Number","lazy-rules":"","rules":[
              function (val) { return (val.trim() && val.length > 0) || 'Please type something'; } ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"cancel"},on:{"click":function($event){_vm.text = ''}}})]},proxy:true},{key:"hint",fn:function(){return [_vm._v(" Type the phone number of the institution ")]},proxy:true}]),model:{value:(_vm.financialInstitution.phone),callback:function ($$v) {_vm.$set(_vm.financialInstitution, "phone", $$v)},expression:"financialInstitution.phone"}})],1),_c('div',{staticClass:"row items-start q-pl-md q-col-gutter-x-md"},[_c('q-input',{staticClass:"col-12 col-md-12",attrs:{"outlined":"","bottom-slots":"","label":"Physical address","lazy-rules":"","rules":[
              function (val) { return (val.trim() && val.length > 0) || 'Please type something'; } ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"cancel"},on:{"click":function($event){_vm.text = ''}}})]},proxy:true},{key:"hint",fn:function(){return [_vm._v(" Type the address of the institution ")]},proxy:true}]),model:{value:(_vm.financialInstitution.physicalAddress),callback:function ($$v) {_vm.$set(_vm.financialInstitution, "physicalAddress", $$v)},expression:"financialInstitution.physicalAddress"}})],1),_c('div',{staticClass:"row items-start q-pl-md q-col-gutter-x-md"},[_c('q-input',{staticClass:"col-12 col-md-8",attrs:{"outlined":"","bottom-slots":"","label":"Email","lazy-rules":"","rules":[
              function (val) { return (val.trim() && val.length > 0) || 'Please type something'; } ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"email"}})]},proxy:true},{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"cancel"},on:{"click":function($event){_vm.text = ''}}})]},proxy:true}]),model:{value:(_vm.financialInstitution.email),callback:function ($$v) {_vm.$set(_vm.financialInstitution, "email", $$v)},expression:"financialInstitution.email"}}),_c('q-select',{staticClass:"col-12 col-md-4",attrs:{"outlined":"","bottom-slots":"","emit-value":"","map-options":"","autofocus":"","counter":"","options":_vm.types},scopedSlots:_vm._u([{key:"hint",fn:function(){return [_vm._v(" Select the type of institution ")]},proxy:true}]),model:{value:(_vm.financialInstitution.type),callback:function ($$v) {_vm.$set(_vm.financialInstitution, "type", $$v)},expression:"financialInstitution.type"}})],1),_c('div',{staticClass:"q-gutter-md row items-start q-pl-md q-col-gutter-x-md"},[_c('q-btn',{attrs:{"label":"Submit","type":"submit","color":"blue","icon-right":"edit"}}),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"label":"Reset","type":"reset","color":"primary","flat":""}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }